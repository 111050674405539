import { STAGE } from '../_app/constants/common'
import { LazyLoadRetryOnce } from './LazyLoad'
import { appBuildType } from '../_app/utils/userAgent'

const internalPages = () => {
  if (STAGE === 'development') {
    return {
      Home: LazyLoadRetryOnce(() => import('../_pages/PagePortal')),
      GraphQL: LazyLoadRetryOnce(() => import('../_pages/PageEmpty')),
      Portal: LazyLoadRetryOnce(() => import('../_pages/PagePortal')),
      // TODO: bundle 속도 이슈가 있어서 임시로 분리
      // GraphQL: LazyLoadRetryOnce(() => import('../_pages/PageGraphQL')),
    }
  }

  if (appBuildType(window.navigator.userAgent).isDebugApp) {
    return {
      Home: LazyLoadRetryOnce(() => import('../_pages/PageEmpty')),
      GraphQL: LazyLoadRetryOnce(() => import('../_pages/PageEmpty')),
      Portal: LazyLoadRetryOnce(() => import('../_pages/PagePortal')),
    }
  }

  return {
    Home: LazyLoadRetryOnce(() => import('../_pages/PageEmpty')),
    GraphQL: LazyLoadRetryOnce(() => import('../_pages/PageEmpty')),
    Portal: LazyLoadRetryOnce(() => import('../_pages/PageEmpty')),
  }
}

export const activities = {
  ...internalPages(),
  FleaMarketFilterRegion: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterRegion')
  ),
  FleaMarketFilterPrice: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterPrice')
  ),
  FleaMarketFilterCategory: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterCategory')
  ),
  FleaMarketFilterSort: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterSort')
  ),
  FleaMarketFilterRegionRange: LazyLoadRetryOnce(
    () => import('../_pages/PageFleaMarketFilterRegionRange')
  ),
  HomeSearch: LazyLoadRetryOnce(() => import('../_pages/PageHomeSearch')),
  /**
   * @Deprecated Custom Webview Entry Page
   */
  Search: LazyLoadRetryOnce(
    () => import('../__deprecated/_pages/PageAggregatedSearch')
  ),
  /**
   * @Deprecated Custom Webview Entry Page
   */
  SearchDiscover: LazyLoadRetryOnce(
    () => import('../__deprecated/_pages/PageAggregatedSearchDiscover')
  ),
  AppLoginGuide: LazyLoadRetryOnce(() => import('../_pages/PageAppLoginGuide')),
  AppError: LazyLoadRetryOnce(() => import('../_pages/PageAppError')),
  ActionSheetPlaceAdMore: LazyLoadRetryOnce(
    () => import('../_pages/PageActionSheetPlaceAdMore')
  ),
  ActionSheetCatalogProductAdMore: LazyLoadRetryOnce(
    () => import('../_pages/PageActionSheetCatalogProductAdMore')
  ),
  ActionSheetNeighborAdMore: LazyLoadRetryOnce(
    () => import('../_pages/PageActionSheetNeighborAdMore')
  ),
  ActionSheetNeighborAdInfo: LazyLoadRetryOnce(
    () => import('../_pages/PageActionSheetNeighborAdInfo')
  ),
  ActionSheetKeywordNotification: LazyLoadRetryOnce(
    () => import('../_pages/PageActionSheetKeywordNotification')
  ),
  RegisterUserKeywordError: LazyLoadRetryOnce(
    () => import('../_pages/PageRegisterUserKeywordError')
  ),
  SatisfactionSurveyForm: LazyLoadRetryOnce(
    () => import('../_pages/PageSatisfactionSurveyForm')
  ),
}

export type SearchActivitiesType = keyof typeof activities

export const SEARCH_ACTIVITY_KEYS = Object.keys(activities).reduce<{
  [key in keyof typeof activities]: key
}>((acc, cur) => {
  return {
    ...acc,
    [cur]: cur,
  }
}, {} as any)
