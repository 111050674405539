import type { StateCreator } from 'zustand'

export const BUSINESS_FILTER_SORTS = {
  RELEVANT: {
    ID: 'RELEVANT',
    LABEL: '정확도순',
  },
  RECENT: {
    ID: 'RECENT',
    LABEL: '최신순',
  },
} as const

export type BusinessSortIdType =
  (typeof BUSINESS_FILTER_SORTS)[keyof typeof BUSINESS_FILTER_SORTS]['ID']

interface FilterSortType {
  sortId: BusinessSortIdType
}

export type BusinessPostFilterSliceType = {
  businessPostFilter: {
    sortId: BusinessSortIdType
    changeSort: (range: FilterSortType) => void
  }
}

export const businessPostFilterSlice: StateCreator<
  BusinessPostFilterSliceType
> = (set, getState) => ({
  businessPostFilter: {
    sortId: BUSINESS_FILTER_SORTS.RELEVANT.ID,

    changeSort: (range: FilterSortType) =>
      set((state) => ({
        businessPostFilter: {
          ...state.businessPostFilter,
          sortId: range.sortId,
        },
      })),
  },
})
