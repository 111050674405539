import {
  SEARCH_LOCAL_STORAGE_STATE_KEY,
  SEARCH_LOCAL_STORAGE_FOUNDATION_OVERRIDE_KEY,
  SEARCH_COOKIE_STORAGE_FOUNDATION_OVERRIDE_KEY,
  SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS_KEY,
  SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS,
} from '../constants/common'
import type {
  DEFAULT_APP,
  DEFAULT_GEOLOCATION,
  DEFAULT_REGION,
  DEFAULT_USER,
} from '../../bridge/constants'

export interface PersistedRecentSearchHistory {
  query: string
  eventTime: string
}

interface PersistedState {
  hoianSearchHistories: string[]
  recentSearchHistories: PersistedRecentSearchHistory[]
  hideClosedArticles: boolean
  showKeywordNotificationAnnouncement: boolean
  showKeywordNotificationActionSheet: boolean
  // NOTE: 통합탭 실험 키워드 알림 버블
  showExperimentalKeywordNotificationAnnouncement: boolean
  // NOTE: 중고거래탭 키워드 알림 버블
  showFleamarketTabKeywordNotificationBubble: boolean
}

export function setPersistedState(
  state: Partial<PersistedState>
): PersistedState {
  const prevState = getPersistedState()
  const nextState = {
    ...prevState,
    ...state,
  }

  // 브라우저 로컬스토리지
  localStorage.setItem(
    SEARCH_LOCAL_STORAGE_STATE_KEY,
    JSON.stringify(nextState)
  )

  return nextState
}

export function getPersistedState(): PersistedState {
  const state = localStorage.getItem(SEARCH_LOCAL_STORAGE_STATE_KEY)

  return {
    ...getInitialPersistedState(),
    ...(state ? (JSON.parse(state) as PersistedState) : null),
  }
}

function getInitialPersistedState(): PersistedState {
  return {
    hoianSearchHistories: [],
    recentSearchHistories: [],
    hideClosedArticles: false,
    showKeywordNotificationAnnouncement: true,
    showKeywordNotificationActionSheet: true,
    showExperimentalKeywordNotificationAnnouncement: true,
    showFleamarketTabKeywordNotificationBubble: true,
  }
}

/**
 * Staging과 프로덕션 테스트에 사용되는 state, foundation override 용도
 * @param state
 */
interface FoundationOverridePersistedState {
  region: typeof DEFAULT_REGION | null
  user: typeof DEFAULT_USER | null
  app: typeof DEFAULT_APP | null
  geolocation: typeof DEFAULT_GEOLOCATION | null
}

export const setFoundationOverridePersistedState = (
  state: Partial<FoundationOverridePersistedState>
): FoundationOverridePersistedState => {
  const prevState = getFoundationOverridePersistedState()
  const nextState = {
    ...prevState,
    ...state,
  }

  localStorage.setItem(
    SEARCH_LOCAL_STORAGE_FOUNDATION_OVERRIDE_KEY,
    JSON.stringify(nextState)
  )

  return nextState
}

export const getCookie = (name: string) => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\+^])/g, '\\$1') + '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

/**
 * 브릿지에서 가져오는 foundation을 override하기 위한 용도
 * Staging, 어드민 preview에서 사용
 * 실제 서비스 영향 X
 */
const getFoundationOverrideInStore = () => {
  const state = localStorage.getItem(
    SEARCH_LOCAL_STORAGE_FOUNDATION_OVERRIDE_KEY
  )

  if (!state) {
    return getCookie(SEARCH_COOKIE_STORAGE_FOUNDATION_OVERRIDE_KEY)
  }

  return state
}

export const getFoundationOverridePersistedState =
  (): FoundationOverridePersistedState => {
    const state = getFoundationOverrideInStore()

    const initial = (): FoundationOverridePersistedState => {
      return {
        region: null,
        user: null,
        app: null,
        geolocation: null,
      }
    }

    try {
      return {
        ...initial(),
        ...(state
          ? (JSON.parse(state) as FoundationOverridePersistedState)
          : null),
      }
    } catch (e) {
      return initial()
    }
  }

/**
 * 최근 검색어 마이그레이션 상태를 tracking하기 위한 status 정보
 */
export const getRecentSearchMigrationStatusInStore = () => {
  const state = localStorage.getItem(
    SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS_KEY
  )

  switch (state) {
    case 'WITH_SERVER_RESPONSE':
      return SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS.WITH_SERVER_RESPONSE
    case 'DONE':
      return SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS.DONE
    case 'IN_MIGRATION':
    default:
      return SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS.IN_MIGRATION
  }
}

export const setRecentSearchMigrationStatusToStore = (
  status: (typeof SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS)[keyof typeof SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS]
) => {
  localStorage.setItem(
    SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS_KEY,
    status
  )
}
