import type { ReactNode } from 'react'
import type { MutableSnapshot } from 'recoil'
import { RecoilRoot } from 'recoil'
import { useExperimentContext } from './AppInitializeProvider'
import { experimentState as store } from '../../experiment/stores/ExperimentStore'
import { GlobalStoreProvider } from '../stores/GlobalStoreProvider'

interface GlobalMutableStateProviderProps {
  children: ReactNode
}

const GlobalMutableStateProvider = (props: GlobalMutableStateProviderProps) => {
  const { experimentState } = useExperimentContext()

  const injectInitialExperimentState = ({ set }: MutableSnapshot) => {
    set(store, {
      experimentState,
    })
  }

  return (
    <RecoilRoot initializeState={injectInitialExperimentState}>
      <GlobalStoreProvider
        initGlobalState={{
          experimentState,
        }}
      >
        {props.children}
      </GlobalStoreProvider>
    </RecoilRoot>
  )
}

export default GlobalMutableStateProvider
