import type { StateCreator } from 'zustand'

export const COMMUNITY_FILTER_SORTS = {
  RELEVANT: {
    ID: 'RELEVANT',
    LABEL: '정확도순',
  },
  RECENT: {
    ID: 'RECENT',
    LABEL: '최신순',
  },
} as const

export type CommunitySortIdType =
  (typeof COMMUNITY_FILTER_SORTS)[keyof typeof COMMUNITY_FILTER_SORTS]['ID']

interface FilterSortType {
  sortId: CommunitySortIdType
}

export type CommunityFilterSliceType = {
  communityFilter: {
    sortId: CommunitySortIdType
    changeSort: (range: FilterSortType) => void
  }
}

export const communityFilterSlice: StateCreator<CommunityFilterSliceType> = (
  set,
  getState
) => ({
  communityFilter: {
    sortId: COMMUNITY_FILTER_SORTS.RELEVANT.ID,

    changeSort: (range: FilterSortType) =>
      set((state) => ({
        communityFilter: {
          ...state.communityFilter,
          sortId: range.sortId,
        },
      })),
  },
})
