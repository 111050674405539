import type { CommunitySortIdType } from '../../community/stores/communityFilterSlice'
import {
  COMMUNITY_FILTER_SORTS,
  communityFilterSlice,
} from '../../community/stores/communityFilterSlice'
import type { GlobalState } from './GlobalStore'
import type { StateCreator } from 'zustand'
import type { BusinessSortIdType } from '../../businessPost/stores/businessPostFilterSlice'
import {
  BUSINESS_FILTER_SORTS,
  businessPostFilterSlice,
} from '../../businessPost/stores/businessPostFilterSlice'

export type GlobalStoreActionTypes = {
  // 동네생활
  communityFilterChangeSort: (params: { sortId: CommunitySortIdType }) => void
  communityFilterResetSort: () => void
  // 동네홍보
  businessPostFilterChangeSort: (params: { sortId: BusinessSortIdType }) => void
  businessPostFilterResetSort: () => void
}

export const globalStoreActions: StateCreator<
  GlobalState,
  [],
  [],
  GlobalStoreActionTypes
> = (set, get, api) => ({
  communityFilterChangeSort: ({ sortId }: { sortId: CommunitySortIdType }) => {
    communityFilterSlice(set, get, api).communityFilter.changeSort({ sortId })
  },
  communityFilterResetSort: () => {
    communityFilterSlice(set, get, api).communityFilter.changeSort({
      sortId: COMMUNITY_FILTER_SORTS.RELEVANT.ID,
    })
  },
  businessPostFilterChangeSort: ({
    sortId,
  }: {
    sortId: BusinessSortIdType
  }) => {
    businessPostFilterSlice(set, get, api).businessPostFilter.changeSort({
      sortId,
    })
  },
  businessPostFilterResetSort: () => {
    businessPostFilterSlice(set, get, api).businessPostFilter.changeSort({
      sortId: BUSINESS_FILTER_SORTS.RELEVANT.ID,
    })
  },
})
